define("ember-data-storefront/-private/utils/get-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shoeboxize = _exports.serializeObject = _exports.queryCacheKey = _exports.cacheKey = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _serializeParams = function _serializeParams() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var prefix = arguments.length > 1 ? arguments[1] : undefined;
    var query = Object.keys(params).sort().map(function (key) {
      var value = params[key];
      if (Array.isArray(params)) {
        key = "".concat(prefix, "[]");
      } else if (params === Object(params)) {
        key = prefix ? "".concat(prefix, "[").concat(key, "]") : key;
      }
      if (_typeof(value) === 'object' && value !== null) {
        return _serializeParams(value, key);
      } else {
        return "".concat(key, "=").concat(encodeURIComponent(value));
      }
    });
    return [].concat.apply([], query).join('&');
  };
  var serializeObject = function serializeObject(params) {
    return _serializeParams(params);
  };
  _exports.serializeObject = serializeObject;
  var queryCacheKey = function queryCacheKey(query) {
    return cacheKey([query.type, query.id, query.params]);
  };
  _exports.queryCacheKey = queryCacheKey;
  var cacheKey = function cacheKey(args) {
    return args.map(function (part) {
      return _typeof(part) === "object" ? serializeObject(part) : part;
    }).filter(function (part) {
      return !!part;
    }).join('::');
  };
  _exports.cacheKey = cacheKey;
  var shoeboxize = function shoeboxize(key) {
    return key.replace(/&/g, '--'); // IDGAF
  };
  _exports.shoeboxize = shoeboxize;
});