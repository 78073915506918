define("ember-composable-helpers/utils/comparison", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gt = gt;
  _exports.gte = gte;
  _exports.lt = lt;
  _exports.lte = lte;
  function lte(a, b) {
    return a <= b;
  }
  function lt(a, b) {
    return a < b;
  }
  function gte(a, b) {
    return a >= b;
  }
  function gt(a, b) {
    return a > b;
  }
});