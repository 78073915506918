define("ember-macro-helpers/expand-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(property) {
    var newPropertyList = [];
    Ember.expandProperties(property, function (expandedProperties) {
      newPropertyList = newPropertyList.concat(expandedProperties);
    });
    return newPropertyList;
  }
});