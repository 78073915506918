define("ember-cli-string-helpers/helpers/dasherize", ["exports", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.dasherize = void 0;
  var dasherize = (0, _createStringHelper.default)(Ember.String.dasherize);
  _exports.dasherize = dasherize;
  var _default = Ember.Helper.helper(dasherize);
  _exports.default = _default;
});