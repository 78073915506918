define("ember-in-viewport/index", ["exports", "ember-in-viewport/mixins/in-viewport"], function (_exports, _inViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-mixins
  var _default = _inViewport.default;
  _exports.default = _default;
});