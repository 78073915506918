define("ember-in-viewport/utils/check-scroll-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkScrollDirection;
  var floor = Math.floor;
  function checkScrollDirection() {
    var lastPosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var newPosition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var sensitivity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    if (!lastPosition) {
      return 'none';
    }
    (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));
    var top = newPosition.top,
      left = newPosition.left;
    var lastTop = lastPosition.top,
      lastLeft = lastPosition.left;
    var delta = {
      top: floor((top - lastTop) / sensitivity) * sensitivity,
      left: floor((left - lastLeft) / sensitivity) * sensitivity
    };
    if (delta.top > 0) {
      return 'down';
    }
    if (delta.top < 0) {
      return 'up';
    }
    if (delta.left > 0) {
      return 'right';
    }
    if (delta.left < 0) {
      return 'left';
    }
  }
});