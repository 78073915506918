define("ember-power-select/components/power-select/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @placeholder}}
    <span class="ember-power-select-placeholder" ...attributes>{{@placeholder}}</span>
  {{/if}}
  
  */
  {
    "id": "fyXJq24a",
    "block": "{\"symbols\":[\"&attrs\",\"@placeholder\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"span\"],[24,0,\"ember-power-select-placeholder\"],[17,1],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-power-select/components/power-select/placeholder.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});